@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,800);
@import url(https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/3.3.7/css/bootstrap.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 500px) {
    .col-sm-6 {
        width: 50%;
    }
}

html, body {
    height: 100%;
    min-height: 18em;
}

.frontend-side {
    background-image: url("/Images/darts-2265723_1280.jpg");
    background-size: contain;
    background-position: bottom;
}

.uiux-side {
    background-image: url("/Images/billiards-3423614_1280.jpg");
    background-size: cover;
}

.split-pane {
    padding-top: 1em;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 50%;
    min-height: 9em;
    font-size: 2em;
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    ;
}

@media(min-width: 500px) {
    .split-pane {
        padding-top: 2em;
        height: 100%;
    }
}

.split-pane > div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
}

    .split-pane > div .text-content {
        line-height: 1.6em;
        margin-bottom: 1em;
    }

        .split-pane > div .text-content .big {
            font-size: 2em;
        }

    .split-pane > div img {
        height: 1.3em;
    }

@media (max-width: 500px) {
    .split-pane > div img {
        display: none;
    }
}

.split-pane button, .split-pane a.button {
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
    background: none;
    border: 1px solid white;
    border-radius: 5px;
    width: 15em;
    padding: 0.7em;
    font-size: 0.5em;
    transition: all 0.2s ease-out;
    text-decoration: none;
    color: white;
    display: inline-block;
    cursor: pointer;
}

    .split-pane button:hover, .split-pane a.button:hover {
        text-decoration: none;
        background-color: white;
        border-color: white;
        cursor: pointer;
    }

    .uiux-side.split-pane button:hover, .split-pane a.button:hover {
        color: violet;
    }

    .frontend-side.split-pane button:hover, .split-pane a.button:hover {
        color: blue;
    }

#split-pane-or {
    font-size: 2em;
    color: white;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

@media (max-width: 925px) {
    #split-pane-or {
        top: 15%;
    }
}

#split-pane-or > div img {
    height: 2.5em;
}

@media (max-width: 500px) {
    #split-pane-or {
        position: absolute;
        top: 50px;
    }

        #split-pane-or > div img {
            height: 2em;
        }

    .bold {
        font-size: 20px;
    }
}

@media(min-width: 500px) {
    #split-pane-or {
        font-size: 3em;
    }
}

.big {
    font-size: 2em;
}

#slogan {
    position: absolute;
    width: 100%;
    z-index: 100;
    text-align: center;
    vertical-align: baseline;
    top: 0.5em;
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.4em;
}

@media(min-width: 500px) {
    #slogan {
        top: 5%;
        font-size: 1.8em;
    }
}

#slogan img {
    height: 0.7em;
}

.bold {
    margin: 0.7em;
}

.big {
    font-weight: 800;
}




.clock {
    border-radius: 70px;
    border: 3px solid #777;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
    display: block;
    width: 120px;
    height: 120px;
}

    .clock:after {
        content: "";
        position: absolute;
        background-color: #777;
        top: 13px;
        left: 48%;
        height: 50px;
        width: 4px;
        border-radius: 5px;
        transform-origin: 50% 97%;
        -webkit-animation: grdAiguille 2s linear infinite;
        animation: grdAiguille 2s linear infinite;
    }

@-webkit-keyframes grdAiguille {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes grdAiguille {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.clock:before {
    content: "";
    position: absolute;
    background-color: #777;
    top: 24px;
    left: 48%;
    height: 40px;
    width: 4px;
    border-radius: 5px;
    transform-origin: 50% 94%;
    -webkit-animation: ptAiguille 12s linear infinite;
    animation: ptAiguille 12s linear infinite;
}

@-webkit-keyframes ptAiguille {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes ptAiguille {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

